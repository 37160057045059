import './dsc-footer.scss'
import '@engie-group/fluid-design-system/lib/components/footer/index.css'
import { type Links, type TMenuLinks } from 'types/footer'
import type { FC } from 'react'
import { NJFooter } from '@engie-group/fluid-design-system-react'

interface DSCFooterProps {
  className?: string
  logoPath?: string
  logoAlt?: string
  sloganText?: string
  menuLists?: TMenuLinks
  links?: Links
}

const DSCFooter: FC<DSCFooterProps> = ({
  className,
  logoPath,
  logoAlt,
  sloganText,
  menuLists,
  links
}: DSCFooterProps) => {
  return (
    <div className="dsc-footer">
      <NJFooter
        {...{ className, logoPath, logoAlt, sloganText, menuLists, links }}
        links={[
          {
            text: 'Privacy Policy',
            url: 'https://agora-strapi.dsa-prod.myoddcloud.com/uploads/DSC_Privacy_Policy_EN_and_FR_0ccab4af45.pdf'
          },
          {
            text: 'Group Data Policy',
            url: 'https://agora-strapi.dsa-prod.myoddcloud.com/uploads/ENGIE_DATA_POLICY_V1_097504e872.pdf'
          },
          {
            text: 'Agora',
            url: 'https://agora.engie.com/'
          },
          {
            text: 'Common Data Hub',
            url: 'https://commondatahub.engie.com/'
          },
          {
            text: 'Common API',
            url: 'https://common.api.engie.com/'
          },
          {
            text: 'Contact',
            url: 'mailto:agora@engie.com'
          }
        ]}
      />
    </div>
  )
}

export { DSCFooter }
